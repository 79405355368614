import { render, staticRenderFns } from "./LayoutNav.vue?vue&type=template&id=ba1d22b4&scoped=true"
import script from "./LayoutNav.vue?vue&type=script&lang=js"
export * from "./LayoutNav.vue?vue&type=script&lang=js"
import style0 from "./LayoutNav.vue?vue&type=style&index=0&id=ba1d22b4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba1d22b4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SnippetMenu: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/snippets/SnippetMenu.vue').default,BasicText: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/basic/BasicText.vue').default,BasicLogo: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/basic/BasicLogo.vue').default,BasicButton: require('/var/www/vhosts/anothercodeproject.eu/jgast.anothercodeproject.eu/components/basic/BasicButton.vue').default})
