
import { theme } from '@/tailwind.config.js'

export default {
  data() {
    return {
      isVisible: false,
    }
  },

  watch: {
    item(val) {
      if (val) {
        this.show()
      }
    },
    closeCondition() {
      this.close()
    },
    isFrameVisible(val) {
      if (!val) this.close()
    },
    '$route.path': function () {
      this.close()
    },
  },

  computed: {
    item() {
      return this.$store.state.notification.item
    },
    closeCondition() {
      return this.$store.state.notification.closeKey
    },
    isFrameVisible() {
      return this.$store.state.layout.isFrameVisible
    },
  },

  methods: {
    beforeNavigation() {
      this.close()
    },
    close() {
      this.isVisible = false
      setTimeout(() => {
        this.$store.commit('notification/setItem', null)
      }, theme.transitionDuration['notification'].replace('ms', ''))
    },
    show() {
      this.isVisible = true
    },
  },
}
