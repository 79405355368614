
import marked from '@/mixins/marked'
import pageProperties from '@/mixins/pageProperties'

export default {
  props: {
    item: Object,
    isArrowBefore: Boolean,
    isCenter: Boolean,
    hasNoArrow: Boolean,
    hasNoHoverBG: Boolean,
  },

  data() {
    return {
      title: '',
      isLink: true,
    }
  },

  mixins: [pageProperties, marked],

  methods: {
    /*
     * Saves scroll position
     */
    saveScrollPosition() {
      // console.log('SAVE SCROLL POS')
      sessionStorage.setItem('jgast-scroll-position', window.scrollY)
      sessionStorage.setItem('jgast-scroll-height', document.body.scrollHeight)
    },
  },

  computed: {
    linkComponentProp() {
      if (!this.item || !this.item.content) {
        this.title = this.item.content.text
        this.isLink = false
        return {
          is: 'span',
        }
      }

      const type = this.item.content.linkType
      const intern = this.item.content.linkIntern
      const extern = this.item.content.linkExtern
      const file = this.item.content.linkFile

      if (type === 'intern' && intern) {
        /*
         * a nuxt link to an internal page by page object
         */
        let href = this.getPathByGroup(intern)
        this.title = this.item.content.text
          ? this.item.content.text
          : this.getTitleByGroup(intern)
        return {
          is: 'n-link',
          to: href,
        }
      } else if (type === 'extern' && extern) {
        /*
         * a regular anchor to an external source
         */

        this.title = this.item.content.text ? this.item.content.text : extern
        return {
          is: 'a',
          href: extern,
          target: '_blank',
          rel: 'noopener',
        }
      } else if (type === 'file' && file) {
        /*
         * File
         */
        this.title = this.item.content.text
          ? this.item.content.text
          : file[0].filename
        return {
          is: 'a',
          href: `/uploads/${file[0].filename}`,
          target: '_blank',
          rel: 'noopener',
        }
      } else {
        /*
         * no link
         */
        this.title = this.item.content.text
        this.isLink = false
        return {
          is: 'span',
        }
      }
    },
  },
}
