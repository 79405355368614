
import { theme } from '@/tailwind.config.js'
import pageTransition from '@/mixins/pageTransition.js'

import _throttle from 'lodash.throttle'

export default {
  props: {
    template: String,
    isHome: Boolean,
  },
  mixins: [pageTransition],

  data() {
    return {
      isSpaceAround: false,
      wasFrameVisible: null,
      top: {
        val: 0,
      },
      left: {
        val: 50,
      },
      translateY: {
        val: 0,
      },
      translateX: {
        val: -50,
      },
      scale: {
        val: 1, // 0.9
      },
      opacity: {
        val: 0,
      },
    }
  },

  watch: {
    scrollToTopKey() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    shouldUpdateFrameScrollHeight() {
      this.setFrameScrollHeight()
    },
    '$route.path': function (value) {
      this.$store.commit('carousel/resetCarousel')
      this.$store.commit('layout/setIsFrameVisible', true)
    },
    isFrameVisible(val) {
      this.wasFrameVisible = val
    },
  },

  computed: {
    isFrameVisible() {
      return this.$store.state.layout.isFrameVisible
    },
    breakpoints() {
      return this.$store.state.layout.breakpoints
    },
    shouldUpdateFrameScrollHeight() {
      return this.$store.state.layout.shouldUpdateFrameScrollHeight
    },
    timing() {
      return parseInt(theme.transitionDuration.page.replace('ms', '')) / 1000
    },
    scrollToTopKey() {
      return this.$store.state.layout.scrollToTopKey
    },
  },

  methods: {
    setFrameStyle() {
      if (!this.$refs.frame) return
      let height = null
      let width = null
      let fHeight = null
      let fWidth = null

      if (this.isSpaceAround) {
        height = `${this.$refs.frame.clientHeight}px`
        width = `${this.$refs.frame.clientWidth}px`
        fHeight = this.$refs.frame.clientHeight
        fWidth = this.$refs.frame.clientWidth
      } else {
        height = '100vh' // `${window.innerHeight}px` // '100vh'
        width = '100vw' // `${window.innerWidth}px` // '100vw'
        fHeight = window.innerHeight
        fWidth = window.innerWidth
      }

      document.documentElement.style.setProperty('--frame-height', height)
      document.documentElement.style.setProperty(
        '--inner-height',
        `${window.innerHeight}px`
      )
      document.documentElement.style.setProperty('--frame-width', width)
      document.documentElement.style.setProperty(
        '--inner-width',
        `${window.innerWidth}px`
      )

      this.$store.commit('layout/setFrameHeight', fWidth)
      this.$store.commit('layout/setFrameWidth', fHeight)
    },
    // DEPRECATED
    setIsSpaceAround() {
      // if (this.template === 'form') {
      //   this.isSpaceAround = true
      // } else {
      //   this.isSpaceAround = false
      // }
      // if (window.innerWidth > parseInt(this.breakpoints['sm'])) {
      //   // console.log('SPACE AROUND')
      //   this.isSpaceAround = true
      // } else {
      //   // console.log('NO SPACE AROUND')
      //   this.isSpaceAround = false
      // }
    },
    setBreakpoints() {
      for (screen in theme.screens) {
        if (theme.screens[screen].max) {
          document.documentElement.style.setProperty(
            `--breakpoint-${screen}`,
            `${theme.screens[screen].max}`
          )
          this.$store.commit('layout/setBreakpoint', [
            screen,
            theme.screens[screen].max,
          ])
        }
      }
    },
    setColors() {
      document.documentElement.style.setProperty(
        '--color-blue',
        theme.extend.colors.blue.DEFAULT
      )
      document.documentElement.style.setProperty(
        '--color-black',
        theme.extend.colors.black.DEFAULT
      )
      document.documentElement.style.setProperty(
        '--color-white',
        theme.extend.colors.white.DEFAULT
      )
      document.documentElement.style.setProperty(
        '--color-gray',
        theme.extend.colors.gray.DEFAULT
      )
      document.documentElement.style.setProperty(
        '--color-gray-medium',
        theme.extend.colors.gray.medium
      )
      document.documentElement.style.setProperty(
        '--color-gray-dark',
        theme.extend.colors.gray.dark
      )
    },
    setFrameScrollHeight() {
      if (this.$refs.frame) {
        const rem = parseInt(
          window.getComputedStyle(document.body).getPropertyValue('font-size')
        )
        this.$store.commit(
          'layout/setFrameScrollHeight',
          this.$refs.frame.scrollHeight + 2 * rem
        )
      }
    },
    synchronizeScroll() {
      if (this.$refs.frame && this.isSpaceAround) {
        if (this.isHome) {
          this.$refs.frame.scrollTop =
            window.scrollY > window.innerHeight
              ? window.scrollY - window.innerHeight
              : 0
        } else {
          this.$refs.frame.scrollTop = window.scrollY
        }
      }
    },
    safeScroll: _throttle(function () {
      this.$store.commit('layout/setShouldUpdateFrameScrollHeight')
    }, 500),
  },

  async mounted() {
    /*
     * Further Setup
     */
    this.setColors()
    this.setBreakpoints()
    this.setIsSpaceAround()

    await this.$nextDOMUpdate()

    this.setFrameStyle()
    this.setFrameScrollHeight()

    window.addEventListener('resize', async () => {
      this.setIsSpaceAround()
      await this.$nextDOMUpdate()
      this.setFrameStyle()
      this.setFrameScrollHeight()
    })

    window.addEventListener('scroll', this.synchronizeScroll)
    window.addEventListener('scroll', this.safeScroll)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.synchronizeScroll)
    window.removeEventListener('scroll', this.safeScroll)
  },
}
