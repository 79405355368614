
import pageTransition from '@/mixins/pageTransition'
import routeProperties from '@/mixins/routeProperties'
import isInView from '@/mixins/isInView'
import { gsap } from 'gsap/dist/gsap.js'

export default {
  props: {
    // TODO EVERYTHING AS SETTINGS in other components, instead of isCover || isThisAndThat etc.
    settings: Object,
    item: Object,
  },

  mixins: [isInView, pageTransition, routeProperties],

  data() {
    return {
      videoAction: '',
      opacity: {
        val: 0,
      },
      translate: {
        val: 'translateY(0)',
      },
    }
  },

  watch: {
    isInView(val) {
      if (val) this.videoAction = 'play'
      if (!val) this.videoAction = 'pause'
    },
    isOverlaySeen(val) {
      if (val) this.show()
    },
  },

  methods: {
    handleScroll(e) {
      this.opacity.val = 1 - window.scrollY / (window.innerHeight * 0.5)
    },
    handleClick() {
      if (this.settings.isFullScreen)
        window.scrollTo({
          top: window.innerHeight,
          behavior: 'smooth',
        })
    },
    calculateHeightOffeset() {
      this.translate.val = `translateY(-${
        (this.$refs.isInView.clientHeight - window.innerHeight) / 2
      }px)`
    },
    show() {
      gsap.to(this.opacity, {
        duration: 0.8,
        val: 1,
        delay: 0.6,
        ease: 'Power2.in',
      })
    },
  },

  computed: {
    isOverlaySeen() {
      return this.$store.state.layout.isOverlaySeen
    },
  },

  mounted() {
    if (!this.routeIsHome) this.opacity.val = 1
    if (this.isOverlaySeen) this.show()
    if (this.settings.isFullScreen) this.calculateHeightOffeset()
    if (this.settings.isFullScreen)
      window.addEventListener('scroll', this.handleScroll)
  },

  beforeDestroy() {
    if (this.settings.isFullScreen)
      window.removeEventListener('scroll', this.handleScroll)
  },
}
