
export default {
  data() {
    return {
      backgroundOpacity: 0,
      scrollFrame: null,
      hasHero: false,
    }
  },
  computed: {
    isFormVisible() {
      return (
        !this.$store.state.layout.isTemplateFormVisible &&
        !this.$store.state.layout.isTemplatePageVisible
      )
    },
  },
  methods: {
    handleScroll(el, name) {
      if (!this.hasHero) return

      const y = name === 'window' ? el.scrollY : el.scrollTop
      if (y > window.innerHeight / 2) {
        this.backgroundOpacity = 1
      } else {
        this.backgroundOpacity = 0
      }
    },

    initOpacity() {
      this.backgroundOpacity = 0

      if (this.$store.state.layout.pageTemplate === 'home') {
        this.hasHero = document.querySelector(
          '[page-home]>[snippet-hero].has-media:first-child'
        )
      } else {
        const frames = document.querySelectorAll('[snippet-frame]')

        if (frames[1]) {
          this.hasHero = frames[1].querySelector(
            '[modules]>section>[snippet-hero].has-media:first-child'
          )
        } else if (frames[0]) {
          this.hasHero = frames[0].querySelector(
            '[modules]>section>[snippet-hero].has-media:first-child'
          )
        }
      }

      if (!this.hasHero) {
        this.backgroundOpacity = 1
      }
    },

    initScroll() {
      const frames = document.querySelectorAll('[snippet-frame]')
      if (frames[1]) {
        frames[1].addEventListener(
          'scroll',
          this.handleScroll.bind(this, frames[1], 'frame')
        )
      } else if (frames[0]) {
        frames[0].addEventListener(
          'scroll',
          this.handleScroll.bind(this, frames[0], 'frame')
        )
      }
      window.removeEventListener('scroll', this.handleScroll)
      window.addEventListener(
        'scroll',
        this.handleScroll.bind(this, window, 'window')
      )
    },
  },
  watch: {
    '$store.state.nav.mountedKey': function () {
      this.initOpacity()
      this.initScroll()
    },
  },
}
